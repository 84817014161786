import { createAction, createFeatureSelector, createSelector, props } from '@ngrx/store';
import { IAuditDataParams } from '../audit-data-params.interface';
import { IAuditTrailItem } from '../audit-trail-item.interface';
import { AuditTrailState } from './audit.state';

export enum ActionTypes {
  LOAD_AUDIT_TRAIL_DATA = '[Audit] Load audit trail data',
  EDIT_AUDIT_TRAIL_DATA_META = '[Audit] Edit audit trail data meta',
  AUDIT_TRAIL_DATA_ADD_FILTER = '[Audit] audit trail data add filter',
  RESET_AUDIT_TRAIL_DATA = '[Audit] Reset audit trail data',
  LOAD_AUDIT_TRAIL_DATA_SUCCESS = '[Audit] Load audit trail data success',
  LOAD_AUDIT_TRAIL_DATA_FAILED = '[Audit] Load audit trail data failed',
  GET_AUDIT_ACTION_TYPES = '[Audit Action Types] Get audit action types',
  GET_AUDIT_ACTION_TYPES_SUCCESS = '[Audit Action Types] Get audit action types success',
  GET_AUDIT_ACTION_TYPES_FAILED = '[Audit Action Types] Get audit action types failed',
}

export const editAuditTrailDataMeta = createAction(
  ActionTypes.EDIT_AUDIT_TRAIL_DATA_META,
  props<{
    params: IAuditDataParams;
  }>(),
);
export const loadAuditTrailData = createAction(ActionTypes.LOAD_AUDIT_TRAIL_DATA);
export const addAuditTrailDataFilter = createAction(
  ActionTypes.AUDIT_TRAIL_DATA_ADD_FILTER,
  props<{
    filter: {
      userAction: string;
      fromDate: string;
      toDate: string;
    };
  }>(),
);
export const resetAuditTrailData = createAction(ActionTypes.RESET_AUDIT_TRAIL_DATA);
export const loadAuditTrailDataSuccess = createAction(
  ActionTypes.LOAD_AUDIT_TRAIL_DATA_SUCCESS,
  props<{
    metaData: IAuditDataParams;
    auditItems: IAuditTrailItem[];
  }>(),
);
export const loadAuditTrailDataFailed = createAction(ActionTypes.LOAD_AUDIT_TRAIL_DATA_FAILED);

export const getAuditActionTypes = createAction(ActionTypes.GET_AUDIT_ACTION_TYPES);
export const getAuditActionTypesSuccess = createAction(
  ActionTypes.GET_AUDIT_ACTION_TYPES_SUCCESS,
  props<{
    actionTypes: string[];
  }>(),
);
export const getAuditActionTypesFailed = createAction(ActionTypes.GET_AUDIT_ACTION_TYPES_FAILED);

export const selectAuditTrailState = createFeatureSelector<AuditTrailState>('auditTrail');
export const selectAuditTrailData = createSelector(selectAuditTrailState, state => state.data);
export const selectAuditTrailFilters = createSelector(selectAuditTrailState, state => state.filter);
export const selectAuditTrailMeta = createSelector(selectAuditTrailState, state => state.metaData);
export const selectAuditActionTypes = createSelector(selectAuditTrailState, state => state.actionTypes);
